<template>
  <div class="center navb">
    <template v-if="user">
      <el-row justify="end" align="middle">
        <div class="nav-items">
          <div class="right-side">
            <router-link to="/" class="logo" draggable="false"
              ><img :src="url_logo"
            /></router-link>
            <div class="menu" v-if="user && !user.role.name === 'admin'">
              <router-link :to="`/beta/profile`" v-bind:class="active"
                >Профиль</router-link
              >
              <router-link
                v-if="
                  user &&
                    (!user.hasOwnProperty(`organization`) ||
                      user.organization.length == 0)
                "
                to="/edu"
                v-bind:class="active"
                >Обучение</router-link
              >
              <span class="AskQuestion" @click="AskQuestionShowDialog = true"
                >Задать вопрос
              </span>
            </div>
            <div class="menu" v-if="user && user.role.name === 'admin'">
              <router-link to="/admin/checkTask" v-bind:class="active"
                >Проверка задач</router-link
              >
              <el-dropdown class="admin-dropdown">
                <span>
                  Администрирование
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <router-link to="/admin/project" v-bind:class="active"
                      ><el-dropdown-item
                        >Управление проектами</el-dropdown-item
                      ></router-link
                    >
                    <router-link to="/admin/level" v-bind:class="active"
                      ><el-dropdown-item
                        >Редактирование уровней обучения</el-dropdown-item
                      ></router-link
                    >
                    <router-link to="/competence" v-bind:class="active"
                      ><el-dropdown-item
                        >Конструктор компетенций</el-dropdown-item
                      ></router-link
                    >
                    <!-- <router-link to="/admin/invite" v-bind:class="active"
                      ><el-dropdown-item
                        >Приглашение для госструктур</el-dropdown-item
                      ></router-link
                    > -->
                    <router-link
                      to="/admin/projects/manage-jobs"
                      v-bind:class="active"
                      ><el-dropdown-item
                        >Управление задачами у проектов</el-dropdown-item
                      ></router-link
                    >
                    <!-- <router-link
                      to="/admin/organization/projects"
                      v-bind:class="active"
                      ><el-dropdown-item
                        >Проекты от госструктур</el-dropdown-item
                      ></router-link
                    > -->
                    <router-link
                      to="/admin/project/stages"
                      v-bind:class="active"
                      ><el-dropdown-item
                        >Этапы проекта</el-dropdown-item
                      ></router-link
                    >
                    <router-link
                      to="/admin/company-requests"
                      v-bind:class="active"
                      ><el-dropdown-item
                        >Заявки от партнёров</el-dropdown-item
                      ></router-link
                    >
                    <router-link to="/admin/org-statuses" v-bind:class="active"
                      ><el-dropdown-item
                        >Просмотр задач и проектов от
                        партнеров</el-dropdown-item
                      ></router-link
                    >
                    <router-link
                      to="/admin/moderation/self-project"
                      v-bind:class="active"
                      ><el-dropdown-item
                        >Модерация предложенных проектов</el-dropdown-item
                      ></router-link
                    >
                    <router-link to="/admin/tests/manage" v-bind:class="active"
                      ><el-dropdown-item>Тесты</el-dropdown-item></router-link
                    >
                    <router-link
                      to="/admin/coins-and-achievements"
                      v-bind:class="active"
                      ><el-dropdown-item
                        >БРС для старых пользователей</el-dropdown-item
                      ></router-link
                    >

                    <router-link
                      to="/admin/competence-transfer"
                      v-bind:class="active"
                      ><el-dropdown-item
                        >Трансфер компетенций пользователей</el-dropdown-item
                      ></router-link
                    >

                    <!-- <router-link
                      to="/admin/competence-archieve"
                      v-bind:class="active"
                      ><el-dropdown-item
                        >Архивация компетенций</el-dropdown-item
                      ></router-link
                    > -->
                    <router-link to="/admin/jobs/requests" v-bind:class="active"
                      ><el-dropdown-item
                        >Заявки на задачи</el-dropdown-item
                      ></router-link
                    >
                    <router-link
                      to="/admin/sprints/requests"
                      v-bind:class="active"
                      ><el-dropdown-item
                        >Проверка спринтов</el-dropdown-item
                      ></router-link
                    >
                    <router-link
                      to="/admin/products/manage"
                      v-bind:class="active"
                      ><el-dropdown-item
                        >Управление товарами</el-dropdown-item
                      ></router-link
                    >
                    <router-link
                      to="/admin/products/orders/manage"
                      v-bind:class="active"
                      ><el-dropdown-item
                        >Управление заказами</el-dropdown-item
                      ></router-link
                    >
                    <router-link to="/admin/news/manage" v-bind:class="active"
                      ><el-dropdown-item
                        >Управление новостями</el-dropdown-item
                      ></router-link
                    >
                    <router-link to="/admin/faq/manage" v-bind:class="active"
                      ><el-dropdown-item
                        >Управление FAQ</el-dropdown-item
                      ></router-link
                    >
                    <router-link
                      to="/admin/rating-students-list"
                      v-bind:class="active"
                      ><el-dropdown-item
                        >Статус выпускников</el-dropdown-item
                      ></router-link
                    >
                    <router-link to="/admin/mentors" v-bind:class="active"
                      ><el-dropdown-item
                        >Наставники</el-dropdown-item
                      ></router-link
                    >
                    <router-link to="/admin/events" v-bind:class="active"
                      ><el-dropdown-item>События</el-dropdown-item></router-link
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>

          <div class="userInfo">
            <el-dropdown>
              <span class="el-dropdown-link">
                <template
                  v-if="
                    user &&
                      user.hasOwnProperty(`organization`) &&
                      user.organization.length > 0
                  "
                >
                  {{ user.organization[0].organization_name }}
                </template>
                <template v-else>
                  {{ user.surname + " " + user.name }}
                </template>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <router-link
                    :to="
                      user &&
                      user.hasOwnProperty(`organization`) &&
                      user.organization.length > 0
                        ? `/organization/profile`
                        : `/beta/profile`
                    "
                    ><el-dropdown-item>{{
                      user &&
                      user.hasOwnProperty(`organization`) &&
                      user.organization.length > 0
                        ? `Профиль`
                        : `Мой профиль`
                    }}</el-dropdown-item></router-link
                  >
                  <router-link
                    v-if="
                      user &&
                        (!user.hasOwnProperty(`organization`) ||
                          user.organization.length == 0)
                    "
                    to="/edu"
                    ><el-dropdown-item>Обучение</el-dropdown-item></router-link
                  >
                  <router-link to="/" @click="logout"
                    ><el-dropdown-item>Выход</el-dropdown-item></router-link
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <div class="avatar">
              <el-avatar :src="user.url_img ? user.url_img : url"></el-avatar>
            </div>
          </div>
        </div>

        <div class="nav-mobile-version">
          <img :src="url_logo" />

          <div id="MobileNavbar" @click="openBurgerMenu = true">
            <i class="bx bx-menu burger" />
          </div>
        </div>

        <el-drawer
          v-model="openBurgerMenu"
          direction="ttb"
          modal-class="BurgerMenuButtons"
          :before-close="handleClose"
          :with-header="false"
          size="auto"
        >
          <div class="BurgerMenuButtons">
            <img class="OctagonLogo" :src="url_logo" />
            <router-link
              class="ButtonLink"
              @click="openBurgerMenu = false"
              :to="
                user &&
                user.hasOwnProperty(`organization`) &&
                user.organization.length > 0
                  ? `/organization/profile`
                  : `/beta/profile`
              "
              >{{
                user &&
                user.hasOwnProperty(`organization`) &&
                user.organization.length > 0
                  ? `Профиль`
                  : `Мой профиль`
              }}</router-link
            >
            <router-link
              class="ButtonLink"
              @click="openBurgerMenu = false"
              v-if="
                user &&
                  (!user.hasOwnProperty(`organization`) ||
                    user.organization.length == 0)
              "
              to="/edu"
              >Обучение</router-link
            >
            <router-link
              class="ButtonLink"
              @click="openBurgerMenu = false"
              v-if="
                user &&
                  (!user.hasOwnProperty(`organization`) ||
                    user.organization.length == 0)
              "
              to="/project/change"
              v-bind:class="active"
              >Проект</router-link
            >

            <router-link
              class="ButtonLink"
              to="/"
              @click="
                logout();
                openBurgerMenu = false;
              "
              >Выход</router-link
            >
          </div>
        </el-drawer>
      </el-row>

      <div
        class="AskQuestionShowDialogWrapper"
        v-show="AskQuestionShowDialog"
        @click="AskQuestionShowDialog = false"
      >
        <div class="AskQuestionShowDialog">
          <p class="AskQTitle">Задать вопрос</p>
          <a
            class="AskQTitleLink"
            href="mailto:azaytseva@forus.ru"
            target="_blank"
            >Прохождение практики
            <span class="BoldText">(общение относительно документов)</span></a
          >
          <a
            class="AskQTitleLink"
            href="https://t.me/alinaforus"
            target="_blank"
            >Записаться на консультацию
            <span class="BoldText">(ответы на вопросы больше 15 минут)</span></a
          >
          <p class="AskQTitleChoose">
            <span
              >Общие вопросы
              <span class="BoldText"
                >(ответы на вопросы меньше 15 минут) :
              </span></span
            >
            <a
              href="https://discord.gg/FpaQVyfy6B"
              target="_blank"
              class="AskAppIcon DiscordIcon"
            />
            <a
              href="https://t.me/alinaforus"
              target="_blank"
              class="AskAppIcon TelegramIcon"
            />
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { logout, refresh } from "@/api/auth.js";
import { findById } from "@/api/user.js";
export default {
  data: () => ({
    url_logo: require("@/assets/img/Landing/NewOctagonLogo.svg"),
    url: require("@/assets/img/profile/profile-human-b.png"),
    active: true,
    openBurgerMenu: false,
    AskQuestionShowDialog: false,
  }),
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
  },
  async created() {
    if (this.user === null) {
      const response = await refresh();
      const token = response.accessToken;
      const userResponse = response.user;
      localStorage.setItem("token", token);
      this.getUser(userResponse.id);
    } else {
      this.getUser(this.user.id);
    }
  },
  methods: {
    ...mapMutations("auth", ["SET_USER"]),
    async logout() {
      try {
        logout();
        this.$store.dispatch("auth/logout");
      } catch (error) {
        this.msg = error.response.data.message;
      }
    },
    getUser(id) {
      findById(id).then((response) => {
        this.SET_USER(response.data);
      });
    },
  },
};
</script>

<style scoped>
.admin-dropdown span {
  font-weight: 600;
  color: #5c5c5c !important;
  font-size: 1rem;
  font-family: "Montserrat" !important;
}
.right-side {
  display: flex;
}
.menu {
  margin-left: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  color: #5c5c5c;
  font-size: 1rem;
  font-family: "Montserrat" !important;
}
.menu a {
  margin-right: 20px;
  color: #5c5c5c;
  font-family: "Montserrat" !important;
}
a.router-link-active,
router-link-active > a {
  color: black;
}

.nav-items {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.avatar {
  display: flex;
}
.userInfo {
  display: flex;
  align-items: center;
}
.logo {
  height: inherit;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo img {
  height: 100%;
  pointer-events: none;
}
.el-row {
  padding: 10px 15px !important;
  box-shadow: 1px 1px 10px rgb(211, 211, 211);
  /* margin-bottom: 50px; */
  margin-bottom: -40px !important;
}
.el-dropdown-selfdefine {
  font-family: "Open Sans" !important;
  font-weight: 600 !important;
  margin-right: 10px !important;
  color: #2c3e50 !important;
}
.el-scrollbar {
  border-radius: 15px !important;
}
.el-dropdown__popper,
.el-dropdown__list {
  font-family: "Montserrat" !important;
  color: #2c3e50 !important;
  background-color: rgb(255, 255, 255) !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
}

.el-dropdown-menu__item {
  text-align: start !important;
  margin: 2px 10px !important;
  line-height: 21px !important;
}
.el-dropdown-menu__item:hover {
  font-weight: 700 !important;
  font-family: "Open Sans" !important;
}
</style>

<style scoped>
.AskQuestionShowDialogWrapper {
  position: fixed;
  display: flex;
  top: 0%;
  left: 0%;

  margin: 0%;

  width: 100%;
  height: 100vh;

  z-index: 65000;

  background-color: rgba(0, 0, 0, 0.75);
}
.AskQuestionShowDialogWrapper > .AskQuestionShowDialog {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;

  max-width: fit-content;
  max-height: fit-content;

  padding: 40px 54px;
  margin: auto;

  background: rgba(32, 30, 72, 0.85);

  /* shadow */
  box-shadow: 8px 0px 8px rgba(31, 18, 35, 0.15);
  border-radius: 20px;
  z-index: 1;
}

.AskQTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 155%;

  margin-bottom: 24px;

  color: #ffffff;
}

.AskQuestionShowDialogWrapper .AskQuestionShowDialog > a {
  margin-left: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;

  text-decoration-line: underline;

  color: rgba(255, 255, 255, 1) !important;
}
.AskQuestionShowDialog > a {
  margin-bottom: 20px;
}
.AskQTitleChoose {
  position: relative;
  display: flex;
  flex-direction: row;

  margin-left: 6px;
  margin-bottom: 0%;
}
.AskQTitleChoose > span:nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: rgba(255, 255, 255, 1);
}

.AskQuestionShowDialogWrapper .BoldText {
  font-weight: 700 !important;
  color: #bebebe;
  font-size: 16px;
  text-decoration: none !important;
}

.AskQTitleChoose > * {
  margin-top: auto;
  margin-bottom: auto;
}

.AskQTitleChoose > .DiscordIcon {
  margin-left: 20px;
}
.AskQTitleChoose > .TelegramIcon {
  margin-left: 10px;
}

.AskAppIcon {
  position: relative;
  width: 24px;
  height: 24px;

  filter: drop-shadow(00px 00px 4px #6d6dd9);

  background-position: center;
  background-size: contain;
}

.DiscordIcon {
  background-image: url("./../../assets/img/profile/AskDiscordIcon.png");
}
.TelegramIcon {
  background-image: url("./../../assets/img/profile/AskTelegramIcon.png");
}

.nav-mobile-version {
  position: fixed;

  top: 0%;
  left: 0%;

  margin: 0%;
  padding: 1rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;

  width: 100%;

  /* backdrop-filter: blur(10px); */
}

.nav-mobile-version > img {
  height: 3rem;
  width: auto;
  margin: auto;
  padding-left: 3rem;
}

.nav-mobile-version > #MobileNavbar {
  position: relative;
  width: 3rem;
  height: 3rem;
  display: flex;
  z-index: 1001;
}
.nav-mobile-version > #MobileNavbar > i {
  color: #e0e0e0;
  font-size: 2rem;
  position: relative;
  width: auto;
  height: auto;
  margin: auto;
}

.BurgerMenuButtons {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  width: 100%;
  height: auto;

  padding: 1rem 1rem 3rem 1rem;

  background-color: rgb(20, 20, 20);
}

.BurgerMenuButtons > img.OctagonLogo {
  height: 3rem;
  margin: 4vw auto !important;
}

.BurgerMenuButtons > a.ButtonLink {
  margin: 1vw auto !important;
  font-size: 4vw;
}

.BurgerMenuButtons a.ButtonFooter {
  margin: 2vw auto !important;
  width: 60vw !important;
}
</style>
